/* Header
------------------------------------------------------------*/


/* Main menu
------------------------------------------------------------*/

main {
    padding-top: 99.86px;
    @media only screen and (max-width: 767px) {
        padding-top: 80.87px;
    }
    @media only screen and (max-width: 516px) {
        padding-top: 60.87px;
    }
}

.c {
    &-header {
        position: fixed;
        width: 100%;
        z-index: 2;
        @media only screen and (max-width: 1024px) {
            z-index: 4;
        }
    }
    &-header__menu {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        position: fixed;
        width: 100%;
    }
    &-header__left {
        width: 332px;
        padding-top: 16px;
        background-color: white;
        @media only screen and (max-width: 1024px) {
            width: 390px;
        }
        @media only screen and (max-width: 767px) {
            width: 310px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 0;
        }
        @media only screen and (max-width: 516px) {
            width: 210px;
        }
        a {
            display: block;
            max-width: 199.88px;
            margin: 0 auto;
            @media only screen and (max-width: 1024px) {
                max-width: 180.88px;
            }
            @media only screen and (max-width: 767px) {
                max-width: 158.88px;
            }
            @media only screen and (max-width: 516px) {
                max-width: 115.88px;
            }
        }
    }
    &-header__right {
        width: calc(100% - 332px);
        background-color: #1E9488;
        color: white;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        align-items: center;
        height: 99.87px;
        @media only screen and (max-width: 1124px) {
            width: calc(100% - 67px);
        }
        @media only screen and (max-width: 767px) {
            height: 80.87px;
        }
        @media only screen and (max-width: 516px) {
            height: 60.87px;
        }
        ul {
            height: 100%;
            width: 89%;
            display: -webkit-box;
            display: flex;
            @media only screen and (max-width: 1124px) {
                width: 100%;
            }
            @media only screen and (max-width: 1024px) {
                display: none;
            }
            li {
                height: 100%;
                width: 17%;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                a {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &:last-child {
                    width: 24%;
                    padding-right: 28px;
                    a {
                        width: auto;
                        height: auto;
                    }
                    img {
                        width: 35.61px;
                        margin-right: 10px;
                    }
                }
                &:first-child {
                    &::after {
                        display: none;
                    }
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
                &::after {
                    position: absolute;
                    content: '';
                    background-image: url(../img/arrow_menu.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                    left: 50%;
                    bottom: 13px;
                    width: 14.8px;
                    height: 7.41px;
                    transform: translateX(-50%);
                }
            }
        }
    }
    &-header__meta {
        right: 0;
        position: fixed;
        width: 95.64px;
        z-index: 2;
        top: 99.86px;
    }
    &-header__white {
        width: 95.64px;
    }
    &-header__item {
        &:first-child {
            background-color: rgba(255, 255, 255, 0.849);
            height: 333px;
            padding-top: 22px;
            @media only screen and (max-width: 1024px) {
                background-color: transparent;
                height: auto;
                padding-top: 0;
                margin-top: -65px;
                span {
                    background-color: white;
                }
            }
            @media only screen and (max-width: 767px) {
                margin-top: -74px;
            }
            @media only screen and (max-width: 516px) {
                margin-top: -83px;
                margin-left: 10px;
            }
        }
        &:last-child {
            background-color: #1E9488;
            height: 340px;
            padding-top: 70px;
            z-index: 3;
            a {
                width: 55.54px;
                height: 100%;
                display: block;
                margin: 0 auto 62px;
            }
            @media only screen and (max-width: 1024px) {
                background-color: white;
                padding-top: 0;
                position: fixed;
                left: 0;
                width: 100%;
                display: flex;
                height: 80px;
                bottom: 0;
                align-items: center;
                border-top: 1px solid #1E9488;
                @media only screen and (max-width: 767px) {
                    height: 70px;
                }
                a {
                    justify-content: center;
                    width: 50%;
                    display: flex;
                    margin: 0;
                    &:first-child {
                        border-right: 2px solid #1E9488;
                    }
                    img {
                        width: 55.54px;
                        object-fit: contain;
                        @media only screen and (max-width: 767px) {
                            width: 45.54px;
                        }
                    }
                }
            }
        }
    }
    &-header__burger {}
}

.menu {
    &-toggle {}
}

.c-header__burger {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 34px;
}

.menu-toggle {
    height: 28px;
    width: 38px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    cursor: pointer;
    transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    @media only screen and (max-width: 516px) {
        height: 25px;
        width: 34px;
    }
}

.menu-toggle span {
    height: 3.24px;
    background-color: #1E9488;
    -webkit-transition: width 200ms ease;
    transition: width 200ms ease;
    transition: width 200ms ease;
}

.menu-toggle span:nth-child(2) {
    width: 74%;
    -webkit-transition-delay: 200ms;
    transition-delay: 200ms;
    transition-delay: 200ms;
}

.menu-toggle span:nth-child(1) {
    width: 100%;
}

.menu-toggle span:nth-child(3) {
    width: 48%;
    -webkit-transition-delay: 400ms;
    transition-delay: 400ms;
    transition-delay: 400ms;
}

.menu-toggle span:nth-child(4) {
    position: absolute;
    top: -6px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
    width: 3px;
    height: 0;
    -webkit-transition: height 400ms;
    transition: height 400ms;
    transition: height 400ms;
}

.menu-toggle.c-active {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition-delay: 400ms;
    transition-delay: 400ms;
    transition-delay: 400ms;
}

.menu-toggle.c-active span:nth-child(2) {
    width: 0;
    -webkit-transition: 200ms ease;
    transition: 200ms ease;
    transition: 200ms ease;
}

.menu-toggle.c-active span:nth-child(3) {
    width: 0;
    -webkit-transition: 200ms ease;
    transition: 200ms ease;
    transition: 200ms ease;
    -webkit-transition-delay: 150ms;
    transition-delay: 150ms;
    transition-delay: 150ms;
}

.menu-toggle.c-active span:nth-child(4) {
    height: 35px;
    -webkit-transition: height 200ms ease;
    transition: height 200ms ease;
    transition: height 200ms ease;
    -webkit-transition-delay: 200ms;
    transition-delay: 200ms;
    transition-delay: 200ms;
}

.c-modal__menu {
    top: 88.46px;
    display: block;
    background-color: #3D464C;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    right: 100%;
    position: absolute;
    z-index: -1;
    -webkit-transition: right 1s cubic-bezier(0.42, 0, 0.05, 1.04);
    transition: right 1s cubic-bezier(0.42, 0, 0.05, 1.04);
    padding-bottom: 103px;
    z-index: 99999;
}

@media only screen and (max-width: 516px) {
    .c-modal__menu {
        top: 67.31px;
    }
}

@media only screen and (max-width: 1024px) {
    .c-modal__menu {
        display: block;
    }
}

.c-modal__menu.c-active {
    right: 0;
    z-index: 1001;
}

.c-modal__menu.c-active .c-header__last {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    margin: 50px 0;
}

.list {
    border-bottom: 1px solid white;
}

.item {
    border-top: 1px solid white;
    padding: 25px 0;
}

html.c-active {
    overflow-y: hidden;
}

.item a {
    position: relative;
    display: inline;
    width: 100%;
    min-height: 75px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-sizing: border-box;
    color: white;
    font-size: 1.6rem;
    line-height: 1.4;
    text-decoration: none;
    -webkit-box-align: center;
    align-items: center;
}

.item>dt.accordion a {
    padding-right: 40px;
}

#about_first {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

#about_first a {
    width: auto;
}

.item_add {
    height: 20px;
    width: 20px;
    z-index: 2;
    position: relative;
    margin-right: 20px;
}

.item_add:before,
.item_add:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: white;
    border-radius: 1px;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    right: 0;
}

@media only screen and (max-width: 767px) {
    .item a {
        min-height: 70px;
        font-size: 1.5rem;
    }
}

.item_add:after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.item>dd {
    background: #f0f0f0;
    height: 0;
    overflow: hidden;
}

.item>dd a {
    padding-left: 35px;
    border-bottom: 1px solid;
    color: #3578BE;
    background-color: white;
}

.item>dd a::before {
    margin-left: -20px;
    margin-right: 20px;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    content: "";
    display: inline-block;
    border-right: 1px solid;
    border-bottom: 1px solid;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.item_add:before {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.item>dt.accordion.active .item_add:before,
.item>dt.accordion.active .item_add:after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.item>dd.show {
    height: auto;
}

.item>dd ul li:last-child a {
    border-bottom: none;
}

body.active {
    overflow: hidden;
}

.accordion:before,
.accordion:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 12px;
    height: 2px;
    margin-top: -1px;
    margin-left: -6px;
    background: white;
    border-radius: 1px;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    right: 30px;
}

.accordion:before {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-90deg);
}

.accordion {
    position: relative;
}

.accordion.active::before {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.item.big {
    padding: 0;
}

.accordion {
    padding: 25px 0;
}

body.is-modal-open {
    overflow: hidden;
}