 #fv {
     position: relative;
     margin: 0 auto;
 }
 
 #fv h2 {
     position: absolute;
     z-index: 3;
     width: 31.5%;
     left: 10%;
     top: 39.5%;
     @media only screen and (max-width: 767px) {
         width: 70.5%;
         left: 50%;
         top: 23.5%;
         transform: translateX(-50%);
     }
 }
 
 #fv span {
     background: url(../img/mv_bg.png) center;
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     z-index: 2;
     opacity: 0.5;
 }
 
 .pc {
     display: block;
 }
 
 .sp {
     display: none;
 }
 
 @media only screen and (max-width: 767px) {
     .pc {
         display: none;
     }
     .sp {
         display: block;
     }
 }
 
 .p {
     &-top {}
     &-top1 {
         background-color: #36312F;
         color: white;
         padding: 49px 20px;
     }
     &-top1__content {
         max-width: 1090.37px;
         margin: 0 auto;
     }
     &-top1__title {
         border-bottom: 1px solid;
         font-size: 1.6rem;
         span {
             &:first-child {
                 font-size: 4.6rem;
                 @media only screen and (max-width: 1024px) {
                     font-size: 3.6rem;
                 }
                 @media only screen and (max-width: 516px) {
                     font-size: 3rem;
                 }
             }
         }
     }
     &-top1__list {
         margin-top: 32px;
         letter-spacing: 0px;
         margin-left: 7px;
     }
     &-top1__line {
         display: flex;
         margin-bottom: 38px;
         align-items: center;
         @media only screen and (max-width: 516px) {
             flex-wrap: wrap;
         }
     }
     &-top1__date {
         font-size: 1.4rem;
     }
     &-top1__category {
         font-size: 1.2rem;
         padding: 1px 9px;
         background-color: white;
         color: black;
         margin-left: 35px;
         margin-right: 26px;
         letter-spacing: 2px;
         @media only screen and (max-width: 516px) {
             margin-left: 30px;
             margin-right: 0;
         }
     }
     &-top1__info {
         letter-spacing: 3px;
         @media only screen and (max-width: 516px) {
             width: 100%;
             margin-top: 10px;
         }
     }
     &-top1__link {
         width: 176.99px;
         height: 41.47px;
         display: -webkit-box;
         display: flex;
         -webkit-box-align: center;
         align-items: center;
         border: 1px solid;
         margin: 62px auto 12px;
         justify-content: center;
         position: relative;
         img {
             width: 10.28px;
             position: absolute;
             right: 11px;
         }
     }
     &-top2 {
         margin: 103px 20px 20px;
         @media only screen and (max-width: 767px) {
             margin: 80px 20px 20px;
         }
         @media only screen and (max-width: 516px) {
             margin: 60px 20px 20px;
         }
     }
     &-top2__title {
         font-size: 3.721rem;
         text-align: center;
         color: #1E9488;
         letter-spacing: 3px;
         @media only screen and (max-width: 1024px) {
             font-size: 3.2rem;
         }
         @media only screen and (max-width: 516px) {
             font-size: 3rem;
             letter-spacing: 2px;
         }
     }
     &-top2__desc {
         text-align: center;
         display: block;
         margin-top: 14px;
         letter-spacing: 2px;
         @media only screen and (max-width: 516px) {
             margin-top: 9px;
         }
     }
     &-top2__list {
         display: flex;
         max-width: 1115.21px;
         margin: 55px auto 152px;
         @media only screen and (max-width: 767px) {
             margin: 55px -20px 80px;
             flex-wrap: wrap;
         }
         @media only screen and (max-width: 516px) {
             margin: 40px -20px 60px;
         }
     }
     &-top2__item {
         width: calc(100% / 3);
         position: relative;
         @media only screen and (max-width: 767px) {
             width: 100%;
         }
         img {
             width: 100%;
             height: 100%;
             object-fit: cover;
         }
         span {
             position: absolute;
             top: 50%;
             left: 50%;
             transform: translate(-50%, -50%);
             font-size: 1.8rem;
             color: white;
             width: 100%;
             text-align: center;
         }
     }
     &-top3 {
         background-color: #F7F8F8;
         padding: 67px 20px 98px;
         @media only screen and (max-width: 767px) {
             background-color: transparent;
             padding: 0;
         }
     }
     &-top3__list {
         display: flex;
         max-width: 1115.21px;
         margin: 38px auto;
         @media only screen and (max-width: 767px) {
             flex-wrap: wrap;
         }
     }
     &-top3__item {
         width: calc(100% / 2);
         position: relative;
         @media only screen and (max-width: 767px) {
             width: 100%;
         }
         img {
             width: 100%;
             height: 100%;
             object-fit: cover;
         }
         span {
             position: absolute;
             top: 50%;
             left: 50%;
             transform: translate(-50%, -50%);
             font-size: 1.8rem;
             color: white;
             width: 100%;
             text-align: center;
         }
     }
     &-top4 {
         background-color: #1E9488;
         color: white;
         padding: 73px 20px 64px;
         @media only screen and (max-width: 767px) {
             display: none;
         }
         .p-top2__title {
             color: white;
         }
     }
     &-top4__list {
         max-width: 933.7px;
         margin: 59px auto;
         display: -webkit-box;
         display: flex;
         -webkit-box-pack: justify;
         justify-content: space-between;
     }
     &-top4__item {
         width: 44%;
         background-color: white;
         text-align: center;
         color: black;
         padding: 36px 20px;
         max-height: 188px;
         @media only screen and (max-width: 1024px) {
             width: 46%;
         }
     }
     &-top4__title {
         letter-spacing: 2px;
     }
     &-top4__link {
         width: 241.77px;
         height: 41.47px;
         display: -webkit-box;
         display: flex;
         -webkit-box-align: center;
         align-items: center;
         border: 1px solid;
         margin: 40px auto 12px;
         -webkit-box-pack: center;
         justify-content: center;
         position: relative;
         color: #1E9488;
         border: 1px solid;
         font-size: 1.4rem;
         letter-spacing: 2.5px;
         img {
             width: 10.28px;
             position: absolute;
             right: 18px;
         }
     }
     &-top4__content {}
     &-top4__phone {
         letter-spacing: 3px;
         font-size: 3rem;
         color: #1E9488;
         margin: 17px 0 -5px;
     }
     &-top4__time {
         letter-spacing: 1.5px;
         font-size: 1.758rem;
     }
     &-top5 {
         .p-top1__link {
             color: #1E9387;
             width: 280.99px;
             height: 62.47px;
             margin-bottom: 140px;
             img {
                 right: 20px;
             }
             @media only screen and (max-width: 516px) {
                 width: 257.99px;
                 margin-bottom: 120px;
             }
         }
     }
     &-top5__logo {
         max-width: 237.38px;
         margin: 90px auto 17px;
         @media only screen and (max-width: 767px) {
             max-width: 200.38px;
             margin: 50px auto 17px;
         }
     }
     &-top5__insta {
         max-width: 962.85px;
         margin: 0 auto;
     }
 }
 
 .c {
     &-about {}
     &-about__bg {
         background-image: url(../img/about/about_bg.jpg);
         background-position: center;
         background-size: cover;
         padding: 131px 20px 273px;
         color: white;
         text-align: center;
         @media only screen and (max-width: 767px) {
             padding: 100px 20px;
         }
         @media only screen and (max-width: 516px) {
             padding: 80px 20px;
         }
         h2 {
             font-size: 4.4rem;
             letter-spacing: 9px;
             @media only screen and (max-width: 1024px) {
                 font-size: 4rem;
                 letter-spacing: 5px;
             }
             @media only screen and (max-width: 767px) {
                 font-size: 3.4rem;
                 letter-spacing: 5px;
             }
             @media only screen and (max-width: 516px) {
                 font-size: 3rem;
                 letter-spacing: 2px;
             }
         }
         span {
             margin-top: 17px;
             display: block;
             letter-spacing: 3px;
             @media only screen and (max-width: 1024px) {
                 margin-top: 9px;
             }
             @media only screen and (max-width: 767px) {
                 margin-top: 5px;
             }
         }
     }
     &-about__content {
         background-color: #FFFFFF;
         max-width: 1127.5px;
         margin: -131px auto 0;
         padding: 69px 20px;
         @media only screen and (max-width: 767px) {
             margin: 0 auto 0;
         }
     }
     &-about__part {
         font-size: 1.5rem;
         max-width: 887px;
         margin: 0 auto 198px;
         &:first-child {
             margin: 0 auto 90px;
         }
     }
     &-about__title {
         font-size: 1.8rem;
         position: relative;
         text-align: center;
         &::after {
             content: "";
             position: absolute;
             width: 68.29px;
             height: 4.48px;
             left: 50%;
             -webkit-transform: translateX(-50%);
             transform: translateX(-50%);
             bottom: -8px;
             background-color: #1E9387;
         }
     }
     &-about__list {
         max-width: 886.86px;
         margin: 83px auto 36px;
         display: -webkit-box;
         display: flex;
         -webkit-box-pack: justify;
         justify-content: space-between;
     }
     &-about__image {
         width: 49%;
     }
     &-about__text {
         line-height: 2;
     }
     &-about__sign {
         display: flex;
         -webkit-box-pack: end;
         justify-content: flex-end;
         margin-top: 7px;
     }
     &-about__table {
         max-width: 645.31px;
         font-size: 1.4rem;
         margin-top: 47px;
         margin: 38px auto;
         border: 1px solid;
         span {
             padding: 15px 0;
             display: block;
             &:first-child {
                 width: 149px;
                 background-color: #F4F4F4;
                 text-align: center;
                 border-right: 1px solid;
             }
             &:last-child {
                 padding-left: 19px;
                 width: calc(100% - 149px);
             }
         }
     }
     &-about__line {
         display: flex;
         border-bottom: 1px solid;
         &:last-child {
             border-bottom: none;
         }
     }
     &-about__two {
         display: flex;
         -webkit-box-pack: justify;
         justify-content: space-between;
         max-width: 824.72px;
         margin: 103px auto;
     }
     &-about__one {
         width: 45%;
         h3 {
             font-size: 1.8rem;
             letter-spacing: 4px;
         }
         p {
             font-size: 1.4rem;
             line-height: 1.7;
         }
     }
     &-about__picture {
         margin: 28px 0 20px;
     }
     &-about__map {
         max-width: 880.38px;
         margin: 78px auto 103px;
     }
 }
 
 .engi {}
 
 .c {
     &-busi {
         h3,
         h4 {
             text-align: center;
             color: #1E9387;
         }
         font-size: 1.5rem;
         @media only screen and (max-width: 516px) {
             font-size: 1.4rem;
         }
         .c-about__content {
             padding: 0;
         }
     }
     &-about__bg {
         &.engi {
             background-image: url(../img/busi/engi_bg.jpg);
         }
         &.cons {
             background-image: url(../img/busi/cons_bg.jpg);
         }
         &.real {
             background-image: url(../img/busi/real_bg.jpg);
         }
         &.privacy {
             background-image: url(../img/privacy_bg.jpg);
         }
         &.news {
             background-image: url(../img/news_bg.jpg);
         }
         &.case {
             background-image: url(../img/case/case_bg.jpg);
         }
     }
     &-about__content {}
     &-bus__title {
         font-size: 3.721rem;
         padding-top: 101px;
         letter-spacing: 3px;
         margin-bottom: 13px;
         @media only screen and (max-width: 1024px) {
             font-size: 3.2rem;
         }
         @media only screen and (max-width: 767px) {
             padding-top: 80px;
             font-size: 3rem;
             letter-spacing: 2px;
         }
         @media only screen and (max-width: 516px) {
             padding-top: 60px;
             font-size: 2.5rem;
             margin-bottom: 7px;
         }
     }
     &-bus__desc {
         font-size: 1.6rem;
         text-align: center;
         letter-spacing: 2px;
     }
     &-bus__image {
         margin: 64px 0 97px;
         @media only screen and (max-width: 1024px) {
             margin: 64px 0;
         }
         @media only screen and (max-width: 767px) {
             margin: 50px 0;
         }
         @media only screen and (max-width: 516px) {
             margin: 40px 0;
         }
     }
     &-bus__top {
         max-width: 1030.37px;
         margin: 0 auto;
         padding: 0 20px;
     }
     &-bus__info {
         font-size: 2.4rem;
         line-height: 1.7;
         letter-spacing: 2px;
         @media only screen and (max-width: 767px) {
             font-size: 2rem;
         }
         @media only screen and (max-width: 516px) {
             font-size: 1.8rem;
         }
     }
     &-bus__text {
         margin: 40px 0 172px;
         letter-spacing: 1.5px;
         line-height: 2.1;
         @media only screen and (max-width: 1024px) {
             margin: 40px 0 130px;
         }
         @media only screen and (max-width: 767px) {
             margin: 30px 0 100px;
         }
         @media only screen and (max-width: 516px) {
             margin: 20px 0 80px;
         }
     }
     &-bus__gray {
         background-color: #F5F5F5;
         padding: 88px 0 150px;
         @media only screen and (max-width: 1024px) {
             padding: 88px 0 120px;
         }
         @media only screen and (max-width: 767px) {
             padding: 75px 0 95px;
         }
         @media only screen and (max-width: 516px) {
             padding: 60px 0 70px;
         }
     }
     &-bus__list {
         grid-gap: 3rem;
         display: grid;
         grid-template-columns: repeat(3, 1fr);
         margin: 81px 0 10px;
         @media only screen and (max-width: 1024px) {
             margin: 60px 0 10px;
         }
         @media only screen and (max-width: 767px) {
             margin: 40px 0 10px;
             grid-template-columns: repeat(2, 1fr);
             grid-gap: 3rem 2rem;
         }
         @media only screen and (max-width: 516px) {
             margin: 30px 0 10px;
         }
     }
     &-bus__item {}
     &-bus__picture {}
     &-bus__detail {
         margin-top: 15px;
         letter-spacing: 1px;
         @media only screen and (max-width: 516px) {
             margin-top: 10px;
         }
     }
     &-bus__white {
         margin-top: 95px;
         margin-bottom: 236px;
         @media only screen and (max-width: 1024px) {
             margin-bottom: 170px;
         }
         @media only screen and (max-width: 767px) {
             margin-bottom: 130px;
             margin-top: 75px;
         }
         @media only screen and (max-width: 516px) {
             margin-bottom: 100px;
             margin-top: 56px;
         }
     }
     &-bus__meta {
         grid-gap: 4.5rem 2.5rem;
         display: grid;
         grid-template-columns: repeat(4, 1fr);
         margin: 88px 0 10px;
         @media only screen and (max-width: 1024px) {
             grid-template-columns: repeat(3, 1fr);
             margin: 80px 0 10px;
         }
         @media only screen and (max-width: 767px) {
             grid-gap: 3.5rem 2rem;
             margin: 40px 0 10px;
         }
         @media only screen and (max-width: 516px) {
             grid-template-columns: repeat(2, 1fr);
             grid-gap: 3rem 2rem;
         }
     }
     &-bus__one {}
     &-bus__thumb {}
     &-bus__name {
         text-align: center;
         margin-top: 10px;
         letter-spacing: 1px;
     }
 }
 
 .c-privacy__main {
     margin: 85px 0 124px;
     @media only screen and (max-width: 1024px) {
         margin: 60px 0 100px;
     }
     @media only screen and (max-width: 767px) {
         margin: 30px 0 70px;
     }
     @media only screen and (max-width: 516px) {
         margin: 10px 0 50px;
     }
 }
 
 .c-privacy__part {
     max-width: 712.53px;
     margin: 0 auto;
     h3 {
         font-size: 1.7rem;
         color: #1E9387;
     }
     p {
         margin-top: 3px;
         font-size: 1.4rem;
         line-height: 2.6;
         margin-bottom: 43px;
         @media only screen and (max-width: 767px) {
             margin-bottom: 30px;
         }
     }
 }
 
 .c-privacy__link {
     max-width: 247.21px;
     display: block;
     height: 56.46px;
     display: -webkit-box;
     display: flex;
     -webkit-box-pack: center;
     justify-content: center;
     -webkit-box-align: center;
     align-items: center;
     color: white;
     background-color: #1E9387;
     margin-top: 54px;
     letter-spacing: 1px;
     border-radius: 36px;
     @media only screen and (max-width: 516px) {
         margin-top: 40px;
     }
 }
 
 .c {
     &-news__content {}
     &-news__list {
         max-width: 1065.6px;
         margin: 145px auto 186px;
         padding: 0 20px;
         display: -webkit-box;
         display: flex;
         -webkit-box-pack: justify;
         justify-content: space-between;
         @media only screen and (max-width: 1024px) {
             margin: 145px auto;
         }
         @media only screen and (max-width: 767px) {
             flex-wrap: wrap;
             margin: 100px auto 140px;
         }
         @media only screen and (max-width: 516px) {
             margin: 60px auto 100px;
         }
     }
     &-news__left {
         width: 67%;
         @media only screen and (max-width: 767px) {
             width: 100% !important;
         }
     }
     &-news__part {
         margin-bottom: 145px;
         @media only screen and (max-width: 767px) {
             margin-bottom: 60px;
         }
         @media only screen and (max-width: 516px) {
             margin-bottom: 40px;
         }
         .p {
             &-top1__list {
                 margin-top: 13px;
                 letter-spacing: 0px;
                 margin-left: 4px;
             }
             &-top1__line {
                 border-bottom: 2px dotted #1E9387;
                 padding: 19px 0;
                 margin-bottom: 0;
             }
             &-top1__date {}
             &-top1__info {}
         }
     }
     &-news__category {
         font-size: 2rem;
         color: #1E9387;
         border-bottom: 1px solid;
         padding-bottom: 5px;
         letter-spacing: 3px;
         @media only screen and (max-width: 516px) {
             font-size: 1.8rem;
         }
     }
     &-news__right {
         width: 24%;
         margin-top: 35px;
         @media only screen and (max-width: 767px) {
             width: 100%;
             max-width: 250px;
             margin: 30px auto 0;
         }
         @media only screen and (max-width: 516px) {
             max-width: 200px;
         }
         a {
             background-color: #1E9387;
             color: white;
             display: -webkit-box;
             display: flex;
             -webkit-box-pack: center;
             justify-content: center;
             -webkit-box-align: center;
             align-items: center;
             padding: 23px 0;
             font-size: 2rem;
             margin-bottom: 10px;
             letter-spacing: 2px;
             @media only screen and (max-width: 516px) {
                 font-size: 1.8rem;
             }
         }
     }
 }
 
 .p-top1__category.green {
     background-color: #1E9387;
     color: white;
     display: inline-block;
 }
 
 .c-news__content.detail {
     .p-top1__link {
         color: #1E9387;
         @media only screen and (max-width: 767px) {
             margin: 63px auto 40px;
         }
         @media only screen and (max-width: 516px) {
             margin: 50px auto 30px;
         }
     }
     .c-news__left {
         width: 69.5%;
     }
 }
 
 .c-news__top {
     display: flex;
     align-items: center;
     .p-top1__category.green {
         margin-right: 13px;
         margin-left: 0;
     }
     .p-top1__date {
         letter-spacing: 1px;
     }
 }
 
 .c-news__title {
     font-size: 1.8rem;
     border-bottom: 1px solid #1E9387;
     padding-bottom: 20px;
     margin-top: 17px;
     letter-spacing: 4px;
     @media only screen and (max-width: 516px) {
         font-size: 1.7rem;
         padding-bottom: 15px;
         margin-top: 12px;
         letter-spacing: 2px;
     }
 }
 
 .c-news__text {
     line-height: 2;
     margin: 34px 0 100px;
     font-size: 1.5rem;
     img {
         height: auto;
     }
     @media only screen and (max-width: 767px) {
         margin: 34px 0 80px;
     }
     @media only screen and (max-width: 516px) {
         margin: 30px 0 50px;
         font-size: 1.4rem;
     }
 }
 
 .c-news__page {
     border-top: 1px solid;
     padding-top: 16px;
     font-size: 1.3rem;
     display: -webkit-box;
     display: flex;
     -webkit-box-pack: justify;
     justify-content: space-between;
     margin-bottom: -14px;
 }
 
 .c {
     &-case__list {
         max-width: 1022.07px;
         margin: 107px auto 200px;
         display: -webkit-box;
         display: flex;
         -webkit-box-pack: justify;
         justify-content: space-between;
         @media only screen and (max-width: 1024px) {
             margin: 107px auto;
         }
         @media only screen and (max-width: 767px) {
             margin: 30px auto;
             flex-wrap: wrap;
         }
         @media only screen and (max-width: 516px) {
             margin: 20px auto;
         }
     }
     &-case__left {
         width: 73%;
         @media only screen and (max-width: 767px) {
             width: 100%;
         }
     }
     &-case__link {
         position: relative;
         margin-bottom: 35px;
         span {
             position: absolute;
             top: 50%;
             left: 50%;
             -webkit-transform: translate(-50%, -50%);
             transform: translate(-50%, -50%);
             color: white;
             font-size: 1.8rem;
             letter-spacing: 2px;
             width: 100%;
             text-align: center;
             @media only screen and (max-width: 516px) {
                 font-size: 1.6rem;
             }
         }
     }
     &-case__right {
         width: 21%;
         @media only screen and (max-width: 767px) {
             width: 100%;
             display: flex;
             justify-content: space-between;
         }
     }
     &-case__part {
         margin-bottom: 37px;
         @media only screen and (max-width: 767px) {
             width: 46%;
         }
     }
     &-case__title {
         letter-spacing: 1px;
         background-color: #1E9387;
         color: white;
         padding: 13px 20px;
         font-size: 1.5rem;
     }
     &-case__cate {
         padding: 0 9px;
         background-color: #FAFAFA;
         a {
             display: block;
             font-size: 1.4rem;
             border-bottom: 2px dotted;
             padding: 15px 26px;
             letter-spacing: 2px;
             @media only screen and (max-width: 516px) {
                 padding: 15px 11px;
                 letter-spacing: 1px;
             }
             &:last-child {
                 border-bottom: none;
             }
         }
     }
 }
 
 .c {
     &-cased__content {
         max-width: 1011.62px;
         margin: 0 auto;
         font-size: 1.5rem;
     }
     &-cased__title {
         font-size: 2.4rem;
         color: #1E9387;
         letter-spacing: 3px;
         @media only screen and (max-width: 767px) {
             font-size: 2rem;
             letter-spacing: 2px;
         }
         @media only screen and (max-width: 767px) {
             font-size: 1.8rem;
         }
     }
     &-cased__list {
         display: flex;
         -webkit-box-pack: justify;
         justify-content: space-between;
         margin: 100px 0 200px;
         @media only screen and (max-width: 1024px) {
             margin: 70px 0 120px;
         }
         @media only screen and (max-width: 767px) {
             flex-wrap: wrap;
             margin: 50px 0 70px;
         }
         @media only screen and (max-width: 516px) {
             margin: 30px 0 40px;
         }
     }
     &-cased__left {
         width: 72%;
         grid-gap: 4rem 2rem;
         display: grid;
         grid-template-columns: repeat(3, 1fr);
         @media only screen and (max-width: 767px) {
             width: 100%;
             grid-gap: 3rem 2rem;
         }
         @media only screen and (max-width: 516px) {
             grid-template-columns: repeat(2, 1fr);
         }
     }
     &-cased__item {}
     &-cased__info {
         text-align: center;
         margin-top: 10px;
         letter-spacing: 2px;
     }
     &-cased__right {
         width: 21%;
         @media only screen and (max-width: 1024px) {
             width: 23%;
         }
         @media only screen and (max-width: 767px) {
             @media only screen and (max-width: 767px) {
                 width: 100%;
                 max-width: 250px;
                 margin: 60px auto 0;
             }
             @media only screen and (max-width: 516px) {
                 margin: 40px auto 0;
                 max-width: 200px;
             }
         }
         a {
             display: block;
             background-color: #1E9387;
             color: white;
             padding: 15px 20px;
             margin-bottom: 17px;
             letter-spacing: 1px;
             @media only screen and (max-width: 1024px) {
                 padding: 15px;
                 letter-spacing: 0;
             }
             @media only screen and (max-width: 767px) {
                 padding: 15px 20px;
             }
         }
     }
 }