/* layout
------------------------------------------------------------*/

body {
    font-family: "kozuka-mincho-pro", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 1.5;
    @media only screen and (max-width: 516px) {
        font-size: 1.5rem;
    }
}

//-------------------------------------------------------
main {}

section {}

//-------------------------------------------------------
.l-container {}

//-------------------------------------------------------
.c-mainvisual {}