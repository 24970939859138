/*------------------------------------------------------------
footer
------------------------------------------------------------*/

.c {
    &-footer {
        position: relative;
        z-index: 3;
        @media only screen and (max-width: 1024px) {
            padding-bottom: 80px;
            .pc {
                display: none;
            }
            .sp {
                display: block;
            }
        }
        @media only screen and (max-width: 767px) {
            padding-bottom: 70px;
        }
        .c-footer__top {
            width: 63.33px;
            display: block;
            position: absolute;
            right: 38px;
            top: -94px;
            @media only screen and (max-width: 767px) {
                width: 47.33px;
                right: 20px;
                top: -65px;
            }
        }
    }
    &-footer__list {
        display: flex;
        color: white;
    }
    &-footer__left {
        width: 34%;
        font-size: 1.2rem;
        background-color: #1E9488;
        padding: 91px 20px;
        letter-spacing: 1px;
        @media only screen and (max-width: 1024px) {
            width: 100%;
            font-size: 1.6rem;
            text-align: center;
        }
        @media only screen and (max-width: 516px) {
            font-size: 1.4rem;
        }
    }
    &-footer__logo {
        max-width: 242px;
        margin: 0 auto;
        @media only screen and (max-width: 767px) {
            max-width: 209px;
        }
        @media only screen and (max-width: 516px) {
            max-width: 180px;
        }
    }
    &-footer__address {
        display: flex !important;
        -webkit-box-pack: center;
        justify-content: center;
        margin: 15px 0 80px;
        @media only screen and (max-width: 767px) {
            margin: 15px 0 60px;
        }
    }
    &-footer__copy {
        text-align: center;
    }
    &-footer__right {
        width: 66%;
        background-color: #37A192;
        padding: 113px 20px 10px;
        @media only screen and (max-width: 1024px) {
            display: none;
        }
    }
    &-footer__menu {
        width: 90%;
        margin: 0 auto;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        padding-right: 4%;
        letter-spacing: 2px;
        @media only screen and (max-width: 1124px) {
            width: 100%;
            padding-right: 0;
            letter-spacing: 0;
        }
    }
    &-footer__link {
        &:last-child {
            a {
                margin-bottom: 25px;
            }
        }
        a {
            display: block;
        }
    }
    &-footer__small {
        font-size: 1.4rem;
        margin-top: 14px;
        a {
            margin-bottom: 8px;
        }
    }
}