@charset "UTF-8";
/*------------------------------------------------------------
	Reset
------------------------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

html {
  font-size: 62.5%;
}

body {
  -webkit-text-size-adjust: 100%;
}

body, table,
input, textarea, select, option,
h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

table,
input, textarea, select, option {
  line-height: 1.1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
}

a,
a img {
  transition: 0.3s ease-in-out;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:hover img {
  opacity: 0.80;
  filter: alpha(opacity=80);
  -ms-filter: "alpha(opacity=80)";
}

img {
  max-width: 100%;
}

.sp-only {
  display: none;
}

@media only screen and (max-width: 767px) {
  .pc-only {
    display: none;
  }
  .sp-only {
    display: block;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Layout .l-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/* Header
------------------------------------------------------------*/
/* Main menu
------------------------------------------------------------*/
main {
  padding-top: 99.86px;
}

@media only screen and (max-width: 767px) {
  main {
    padding-top: 80.87px;
  }
}

@media only screen and (max-width: 516px) {
  main {
    padding-top: 60.87px;
  }
}

.c-header {
  position: fixed;
  width: 100%;
  z-index: 2;
}

@media only screen and (max-width: 1024px) {
  .c-header {
    z-index: 4;
  }
}

.c-header__menu {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: fixed;
  width: 100%;
}

.c-header__left {
  width: 332px;
  padding-top: 16px;
  background-color: white;
}

@media only screen and (max-width: 1024px) {
  .c-header__left {
    width: 390px;
  }
}

@media only screen and (max-width: 767px) {
  .c-header__left {
    width: 310px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
  }
}

@media only screen and (max-width: 516px) {
  .c-header__left {
    width: 210px;
  }
}

.c-header__left a {
  display: block;
  max-width: 199.88px;
  margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
  .c-header__left a {
    max-width: 180.88px;
  }
}

@media only screen and (max-width: 767px) {
  .c-header__left a {
    max-width: 158.88px;
  }
}

@media only screen and (max-width: 516px) {
  .c-header__left a {
    max-width: 115.88px;
  }
}

.c-header__right {
  width: calc(100% - 332px);
  background-color: #1E9488;
  color: white;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  height: 99.87px;
}

@media only screen and (max-width: 1124px) {
  .c-header__right {
    width: calc(100% - 67px);
  }
}

@media only screen and (max-width: 767px) {
  .c-header__right {
    height: 80.87px;
  }
}

@media only screen and (max-width: 516px) {
  .c-header__right {
    height: 60.87px;
  }
}

.c-header__right ul {
  height: 100%;
  width: 89%;
  display: -webkit-box;
  display: flex;
}

@media only screen and (max-width: 1124px) {
  .c-header__right ul {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .c-header__right ul {
    display: none;
  }
}

.c-header__right ul li {
  height: 100%;
  width: 17%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-header__right ul li a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-header__right ul li:last-child {
  width: 24%;
  padding-right: 28px;
}

.c-header__right ul li:last-child a {
  width: auto;
  height: auto;
}

.c-header__right ul li:last-child img {
  width: 35.61px;
  margin-right: 10px;
}

.c-header__right ul li:first-child::after {
  display: none;
}

.c-header__right ul li:last-child::after {
  display: none;
}

.c-header__right ul li::after {
  position: absolute;
  content: '';
  background-image: url(../img/arrow_menu.png);
  background-repeat: no-repeat;
  background-size: contain;
  left: 50%;
  bottom: 13px;
  width: 14.8px;
  height: 7.41px;
  transform: translateX(-50%);
}

.c-header__meta {
  right: 0;
  position: fixed;
  width: 95.64px;
  z-index: 2;
  top: 99.86px;
}

.c-header__white {
  width: 95.64px;
}

.c-header__item:first-child {
  background-color: rgba(255, 255, 255, 0.849);
  height: 333px;
  padding-top: 22px;
}

@media only screen and (max-width: 1024px) {
  .c-header__item:first-child {
    background-color: transparent;
    height: auto;
    padding-top: 0;
    margin-top: -65px;
  }
  .c-header__item:first-child span {
    background-color: white;
  }
}

@media only screen and (max-width: 767px) {
  .c-header__item:first-child {
    margin-top: -74px;
  }
}

@media only screen and (max-width: 516px) {
  .c-header__item:first-child {
    margin-top: -83px;
    margin-left: 10px;
  }
}

.c-header__item:last-child {
  background-color: #1E9488;
  height: 340px;
  padding-top: 70px;
  z-index: 3;
}

.c-header__item:last-child a {
  width: 55.54px;
  height: 100%;
  display: block;
  margin: 0 auto 62px;
}

@media only screen and (max-width: 1024px) {
  .c-header__item:last-child {
    background-color: white;
    padding-top: 0;
    position: fixed;
    left: 0;
    width: 100%;
    display: flex;
    height: 80px;
    bottom: 0;
    align-items: center;
    border-top: 1px solid #1E9488;
  }
}

@media only screen and (max-width: 1024px) and (max-width: 767px) {
  .c-header__item:last-child {
    height: 70px;
  }
}

@media only screen and (max-width: 1024px) {
  .c-header__item:last-child a {
    justify-content: center;
    width: 50%;
    display: flex;
    margin: 0;
  }
  .c-header__item:last-child a:first-child {
    border-right: 2px solid #1E9488;
  }
  .c-header__item:last-child a img {
    width: 55.54px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 1024px) and (max-width: 767px) {
  .c-header__item:last-child a img {
    width: 45.54px;
  }
}

.c-header__burger {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 34px;
}

.menu-toggle {
  height: 28px;
  width: 38px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  transition: -webkit-transform 300ms ease;
  transition: -webkit-transform 300ms ease;
  -webkit-transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
}

@media only screen and (max-width: 516px) {
  .menu-toggle {
    height: 25px;
    width: 34px;
  }
}

.menu-toggle span {
  height: 3.24px;
  background-color: #1E9488;
  -webkit-transition: width 200ms ease;
  transition: width 200ms ease;
  transition: width 200ms ease;
}

.menu-toggle span:nth-child(2) {
  width: 74%;
  -webkit-transition-delay: 200ms;
  transition-delay: 200ms;
  transition-delay: 200ms;
}

.menu-toggle span:nth-child(1) {
  width: 100%;
}

.menu-toggle span:nth-child(3) {
  width: 48%;
  -webkit-transition-delay: 400ms;
  transition-delay: 400ms;
  transition-delay: 400ms;
}

.menu-toggle span:nth-child(4) {
  position: absolute;
  top: -6px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transform: translateX(-50%);
  display: block;
  width: 3px;
  height: 0;
  -webkit-transition: height 400ms;
  transition: height 400ms;
  transition: height 400ms;
}

.menu-toggle.c-active {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition-delay: 400ms;
  transition-delay: 400ms;
  transition-delay: 400ms;
}

.menu-toggle.c-active span:nth-child(2) {
  width: 0;
  -webkit-transition: 200ms ease;
  transition: 200ms ease;
  transition: 200ms ease;
}

.menu-toggle.c-active span:nth-child(3) {
  width: 0;
  -webkit-transition: 200ms ease;
  transition: 200ms ease;
  transition: 200ms ease;
  -webkit-transition-delay: 150ms;
  transition-delay: 150ms;
  transition-delay: 150ms;
}

.menu-toggle.c-active span:nth-child(4) {
  height: 35px;
  -webkit-transition: height 200ms ease;
  transition: height 200ms ease;
  transition: height 200ms ease;
  -webkit-transition-delay: 200ms;
  transition-delay: 200ms;
  transition-delay: 200ms;
}

.c-modal__menu {
  top: 88.46px;
  display: block;
  background-color: #3D464C;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  right: 100%;
  position: absolute;
  z-index: -1;
  -webkit-transition: right 1s cubic-bezier(0.42, 0, 0.05, 1.04);
  transition: right 1s cubic-bezier(0.42, 0, 0.05, 1.04);
  padding-bottom: 103px;
  z-index: 99999;
}

@media only screen and (max-width: 516px) {
  .c-modal__menu {
    top: 67.31px;
  }
}

@media only screen and (max-width: 1024px) {
  .c-modal__menu {
    display: block;
  }
}

.c-modal__menu.c-active {
  right: 0;
  z-index: 1001;
}

.c-modal__menu.c-active .c-header__last {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin: 50px 0;
}

.list {
  border-bottom: 1px solid white;
}

.item {
  border-top: 1px solid white;
  padding: 25px 0;
}

html.c-active {
  overflow-y: hidden;
}

.item a {
  position: relative;
  display: inline;
  width: 100%;
  min-height: 75px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-sizing: border-box;
  color: white;
  font-size: 1.6rem;
  line-height: 1.4;
  text-decoration: none;
  -webkit-box-align: center;
  align-items: center;
}

.item > dt.accordion a {
  padding-right: 40px;
}

#about_first {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

#about_first a {
  width: auto;
}

.item_add {
  height: 20px;
  width: 20px;
  z-index: 2;
  position: relative;
  margin-right: 20px;
}

.item_add:before,
.item_add:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background: white;
  border-radius: 1px;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  right: 0;
}

@media only screen and (max-width: 767px) {
  .item a {
    min-height: 70px;
    font-size: 1.5rem;
  }
}

.item_add:after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.item > dd {
  background: #f0f0f0;
  height: 0;
  overflow: hidden;
}

.item > dd a {
  padding-left: 35px;
  border-bottom: 1px solid;
  color: #3578BE;
  background-color: white;
}

.item > dd a::before {
  margin-left: -20px;
  margin-right: 20px;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  content: "";
  display: inline-block;
  border-right: 1px solid;
  border-bottom: 1px solid;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.item_add:before {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.item > dt.accordion.active .item_add:before,
.item > dt.accordion.active .item_add:after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.item > dd.show {
  height: auto;
}

.item > dd ul li:last-child a {
  border-bottom: none;
}

body.active {
  overflow: hidden;
}

.accordion:before,
.accordion:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 12px;
  height: 2px;
  margin-top: -1px;
  margin-left: -6px;
  background: white;
  border-radius: 1px;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  right: 30px;
}

.accordion:before {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-90deg);
}

.accordion {
  position: relative;
}

.accordion.active::before {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.item.big {
  padding: 0;
}

.accordion {
  padding: 25px 0;
}

body.is-modal-open {
  overflow: hidden;
}

/*------------------------------------------------------------
footer
------------------------------------------------------------*/
.c-footer {
  position: relative;
  z-index: 3;
}

@media only screen and (max-width: 1024px) {
  .c-footer {
    padding-bottom: 80px;
  }
  .c-footer .pc {
    display: none;
  }
  .c-footer .sp {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .c-footer {
    padding-bottom: 70px;
  }
}

.c-footer .c-footer__top {
  width: 63.33px;
  display: block;
  position: absolute;
  right: 38px;
  top: -94px;
}

@media only screen and (max-width: 767px) {
  .c-footer .c-footer__top {
    width: 47.33px;
    right: 20px;
    top: -65px;
  }
}

.c-footer__list {
  display: flex;
  color: white;
}

.c-footer__left {
  width: 34%;
  font-size: 1.2rem;
  background-color: #1E9488;
  padding: 91px 20px;
  letter-spacing: 1px;
}

@media only screen and (max-width: 1024px) {
  .c-footer__left {
    width: 100%;
    font-size: 1.6rem;
    text-align: center;
  }
}

@media only screen and (max-width: 516px) {
  .c-footer__left {
    font-size: 1.4rem;
  }
}

.c-footer__logo {
  max-width: 242px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .c-footer__logo {
    max-width: 209px;
  }
}

@media only screen and (max-width: 516px) {
  .c-footer__logo {
    max-width: 180px;
  }
}

.c-footer__address {
  display: flex !important;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 15px 0 80px;
}

@media only screen and (max-width: 767px) {
  .c-footer__address {
    margin: 15px 0 60px;
  }
}

.c-footer__copy {
  text-align: center;
}

.c-footer__right {
  width: 66%;
  background-color: #37A192;
  padding: 113px 20px 10px;
}

@media only screen and (max-width: 1024px) {
  .c-footer__right {
    display: none;
  }
}

.c-footer__menu {
  width: 90%;
  margin: 0 auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-right: 4%;
  letter-spacing: 2px;
}

@media only screen and (max-width: 1124px) {
  .c-footer__menu {
    width: 100%;
    padding-right: 0;
    letter-spacing: 0;
  }
}

.c-footer__link:last-child a {
  margin-bottom: 25px;
}

.c-footer__link a {
  display: block;
}

.c-footer__small {
  font-size: 1.4rem;
  margin-top: 14px;
}

.c-footer__small a {
  margin-bottom: 8px;
}

/*------------------------------------------------------------
sidebar
------------------------------------------------------------*/
/* Breadcrumb
------------------------------------------------------------*/
/* layout
------------------------------------------------------------*/
body {
  font-family: "kozuka-mincho-pro", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.5;
}

@media only screen and (max-width: 516px) {
  body {
    font-size: 1.5rem;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Component .c-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
btn
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
title
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
icon
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
form
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
text
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
navi
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
img
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
list
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
table
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
line
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
other
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Project .p-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
#fv {
  position: relative;
  margin: 0 auto;
}

#fv h2 {
  position: absolute;
  z-index: 3;
  width: 31.5%;
  left: 10%;
  top: 39.5%;
}

@media only screen and (max-width: 767px) {
  #fv h2 {
    width: 70.5%;
    left: 50%;
    top: 23.5%;
    transform: translateX(-50%);
  }
}

#fv span {
  background: url(../img/mv_bg.png) center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0.5;
}

.pc {
  display: block;
}

.sp {
  display: none;
}

@media only screen and (max-width: 767px) {
  .pc {
    display: none;
  }
  .sp {
    display: block;
  }
}

.p-top1 {
  background-color: #36312F;
  color: white;
  padding: 49px 20px;
}

.p-top1__content {
  max-width: 1090.37px;
  margin: 0 auto;
}

.p-top1__title {
  border-bottom: 1px solid;
  font-size: 1.6rem;
}

.p-top1__title span:first-child {
  font-size: 4.6rem;
}

@media only screen and (max-width: 1024px) {
  .p-top1__title span:first-child {
    font-size: 3.6rem;
  }
}

@media only screen and (max-width: 516px) {
  .p-top1__title span:first-child {
    font-size: 3rem;
  }
}

.p-top1__list {
  margin-top: 32px;
  letter-spacing: 0px;
  margin-left: 7px;
}

.p-top1__line {
  display: flex;
  margin-bottom: 38px;
  align-items: center;
}

@media only screen and (max-width: 516px) {
  .p-top1__line {
    flex-wrap: wrap;
  }
}

.p-top1__date {
  font-size: 1.4rem;
}

.p-top1__category {
  font-size: 1.2rem;
  padding: 1px 9px;
  background-color: white;
  color: black;
  margin-left: 35px;
  margin-right: 26px;
  letter-spacing: 2px;
}

@media only screen and (max-width: 516px) {
  .p-top1__category {
    margin-left: 30px;
    margin-right: 0;
  }
}

.p-top1__info {
  letter-spacing: 3px;
}

@media only screen and (max-width: 516px) {
  .p-top1__info {
    width: 100%;
    margin-top: 10px;
  }
}

.p-top1__link {
  width: 176.99px;
  height: 41.47px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid;
  margin: 62px auto 12px;
  justify-content: center;
  position: relative;
}

.p-top1__link img {
  width: 10.28px;
  position: absolute;
  right: 11px;
}

.p-top2 {
  margin: 103px 20px 20px;
}

@media only screen and (max-width: 767px) {
  .p-top2 {
    margin: 80px 20px 20px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top2 {
    margin: 60px 20px 20px;
  }
}

.p-top2__title {
  font-size: 3.721rem;
  text-align: center;
  color: #1E9488;
  letter-spacing: 3px;
}

@media only screen and (max-width: 1024px) {
  .p-top2__title {
    font-size: 3.2rem;
  }
}

@media only screen and (max-width: 516px) {
  .p-top2__title {
    font-size: 3rem;
    letter-spacing: 2px;
  }
}

.p-top2__desc {
  text-align: center;
  display: block;
  margin-top: 14px;
  letter-spacing: 2px;
}

@media only screen and (max-width: 516px) {
  .p-top2__desc {
    margin-top: 9px;
  }
}

.p-top2__list {
  display: flex;
  max-width: 1115.21px;
  margin: 55px auto 152px;
}

@media only screen and (max-width: 767px) {
  .p-top2__list {
    margin: 55px -20px 80px;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 516px) {
  .p-top2__list {
    margin: 40px -20px 60px;
  }
}

.p-top2__item {
  width: calc(100% / 3);
  position: relative;
}

@media only screen and (max-width: 767px) {
  .p-top2__item {
    width: 100%;
  }
}

.p-top2__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-top2__item span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.8rem;
  color: white;
  width: 100%;
  text-align: center;
}

.p-top3 {
  background-color: #F7F8F8;
  padding: 67px 20px 98px;
}

@media only screen and (max-width: 767px) {
  .p-top3 {
    background-color: transparent;
    padding: 0;
  }
}

.p-top3__list {
  display: flex;
  max-width: 1115.21px;
  margin: 38px auto;
}

@media only screen and (max-width: 767px) {
  .p-top3__list {
    flex-wrap: wrap;
  }
}

.p-top3__item {
  width: calc(100% / 2);
  position: relative;
}

@media only screen and (max-width: 767px) {
  .p-top3__item {
    width: 100%;
  }
}

.p-top3__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-top3__item span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.8rem;
  color: white;
  width: 100%;
  text-align: center;
}

.p-top4 {
  background-color: #1E9488;
  color: white;
  padding: 73px 20px 64px;
}

@media only screen and (max-width: 767px) {
  .p-top4 {
    display: none;
  }
}

.p-top4 .p-top2__title {
  color: white;
}

.p-top4__list {
  max-width: 933.7px;
  margin: 59px auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.p-top4__item {
  width: 44%;
  background-color: white;
  text-align: center;
  color: black;
  padding: 36px 20px;
  max-height: 188px;
}

@media only screen and (max-width: 1024px) {
  .p-top4__item {
    width: 46%;
  }
}

.p-top4__title {
  letter-spacing: 2px;
}

.p-top4__link {
  width: 241.77px;
  height: 41.47px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid;
  margin: 40px auto 12px;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  color: #1E9488;
  border: 1px solid;
  font-size: 1.4rem;
  letter-spacing: 2.5px;
}

.p-top4__link img {
  width: 10.28px;
  position: absolute;
  right: 18px;
}

.p-top4__phone {
  letter-spacing: 3px;
  font-size: 3rem;
  color: #1E9488;
  margin: 17px 0 -5px;
}

.p-top4__time {
  letter-spacing: 1.5px;
  font-size: 1.758rem;
}

.p-top5 .p-top1__link {
  color: #1E9387;
  width: 280.99px;
  height: 62.47px;
  margin-bottom: 140px;
}

.p-top5 .p-top1__link img {
  right: 20px;
}

@media only screen and (max-width: 516px) {
  .p-top5 .p-top1__link {
    width: 257.99px;
    margin-bottom: 120px;
  }
}

.p-top5__logo {
  max-width: 237.38px;
  margin: 90px auto 17px;
}

@media only screen and (max-width: 767px) {
  .p-top5__logo {
    max-width: 200.38px;
    margin: 50px auto 17px;
  }
}

.p-top5__insta {
  max-width: 962.85px;
  margin: 0 auto;
}

.c-about__bg {
  background-image: url(../img/about/about_bg.jpg);
  background-position: center;
  background-size: cover;
  padding: 131px 20px 273px;
  color: white;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .c-about__bg {
    padding: 100px 20px;
  }
}

@media only screen and (max-width: 516px) {
  .c-about__bg {
    padding: 80px 20px;
  }
}

.c-about__bg h2 {
  font-size: 4.4rem;
  letter-spacing: 9px;
}

@media only screen and (max-width: 1024px) {
  .c-about__bg h2 {
    font-size: 4rem;
    letter-spacing: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .c-about__bg h2 {
    font-size: 3.4rem;
    letter-spacing: 5px;
  }
}

@media only screen and (max-width: 516px) {
  .c-about__bg h2 {
    font-size: 3rem;
    letter-spacing: 2px;
  }
}

.c-about__bg span {
  margin-top: 17px;
  display: block;
  letter-spacing: 3px;
}

@media only screen and (max-width: 1024px) {
  .c-about__bg span {
    margin-top: 9px;
  }
}

@media only screen and (max-width: 767px) {
  .c-about__bg span {
    margin-top: 5px;
  }
}

.c-about__content {
  background-color: #FFFFFF;
  max-width: 1127.5px;
  margin: -131px auto 0;
  padding: 69px 20px;
}

@media only screen and (max-width: 767px) {
  .c-about__content {
    margin: 0 auto 0;
  }
}

.c-about__part {
  font-size: 1.5rem;
  max-width: 887px;
  margin: 0 auto 198px;
}

.c-about__part:first-child {
  margin: 0 auto 90px;
}

.c-about__title {
  font-size: 1.8rem;
  position: relative;
  text-align: center;
}

.c-about__title::after {
  content: "";
  position: absolute;
  width: 68.29px;
  height: 4.48px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -8px;
  background-color: #1E9387;
}

.c-about__list {
  max-width: 886.86px;
  margin: 83px auto 36px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.c-about__image {
  width: 49%;
}

.c-about__text {
  line-height: 2;
}

.c-about__sign {
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-top: 7px;
}

.c-about__table {
  max-width: 645.31px;
  font-size: 1.4rem;
  margin-top: 47px;
  margin: 38px auto;
  border: 1px solid;
}

.c-about__table span {
  padding: 15px 0;
  display: block;
}

.c-about__table span:first-child {
  width: 149px;
  background-color: #F4F4F4;
  text-align: center;
  border-right: 1px solid;
}

.c-about__table span:last-child {
  padding-left: 19px;
  width: calc(100% - 149px);
}

.c-about__line {
  display: flex;
  border-bottom: 1px solid;
}

.c-about__line:last-child {
  border-bottom: none;
}

.c-about__two {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 824.72px;
  margin: 103px auto;
}

.c-about__one {
  width: 45%;
}

.c-about__one h3 {
  font-size: 1.8rem;
  letter-spacing: 4px;
}

.c-about__one p {
  font-size: 1.4rem;
  line-height: 1.7;
}

.c-about__picture {
  margin: 28px 0 20px;
}

.c-about__map {
  max-width: 880.38px;
  margin: 78px auto 103px;
}

.c-busi {
  font-size: 1.5rem;
}

.c-busi h3,
.c-busi h4 {
  text-align: center;
  color: #1E9387;
}

@media only screen and (max-width: 516px) {
  .c-busi {
    font-size: 1.4rem;
  }
}

.c-busi .c-about__content {
  padding: 0;
}

.c-about__bg.engi {
  background-image: url(../img/busi/engi_bg.jpg);
}

.c-about__bg.cons {
  background-image: url(../img/busi/cons_bg.jpg);
}

.c-about__bg.real {
  background-image: url(../img/busi/real_bg.jpg);
}

.c-about__bg.privacy {
  background-image: url(../img/privacy_bg.jpg);
}

.c-about__bg.news {
  background-image: url(../img/news_bg.jpg);
}

.c-about__bg.case {
  background-image: url(../img/case/case_bg.jpg);
}

.c-bus__title {
  font-size: 3.721rem;
  padding-top: 101px;
  letter-spacing: 3px;
  margin-bottom: 13px;
}

@media only screen and (max-width: 1024px) {
  .c-bus__title {
    font-size: 3.2rem;
  }
}

@media only screen and (max-width: 767px) {
  .c-bus__title {
    padding-top: 80px;
    font-size: 3rem;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 516px) {
  .c-bus__title {
    padding-top: 60px;
    font-size: 2.5rem;
    margin-bottom: 7px;
  }
}

.c-bus__desc {
  font-size: 1.6rem;
  text-align: center;
  letter-spacing: 2px;
}

.c-bus__image {
  margin: 64px 0 97px;
}

@media only screen and (max-width: 1024px) {
  .c-bus__image {
    margin: 64px 0;
  }
}

@media only screen and (max-width: 767px) {
  .c-bus__image {
    margin: 50px 0;
  }
}

@media only screen and (max-width: 516px) {
  .c-bus__image {
    margin: 40px 0;
  }
}

.c-bus__top {
  max-width: 1030.37px;
  margin: 0 auto;
  padding: 0 20px;
}

.c-bus__info {
  font-size: 2.4rem;
  line-height: 1.7;
  letter-spacing: 2px;
}

@media only screen and (max-width: 767px) {
  .c-bus__info {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 516px) {
  .c-bus__info {
    font-size: 1.8rem;
  }
}

.c-bus__text {
  margin: 40px 0 172px;
  letter-spacing: 1.5px;
  line-height: 2.1;
}

@media only screen and (max-width: 1024px) {
  .c-bus__text {
    margin: 40px 0 130px;
  }
}

@media only screen and (max-width: 767px) {
  .c-bus__text {
    margin: 30px 0 100px;
  }
}

@media only screen and (max-width: 516px) {
  .c-bus__text {
    margin: 20px 0 80px;
  }
}

.c-bus__gray {
  background-color: #F5F5F5;
  padding: 88px 0 150px;
}

@media only screen and (max-width: 1024px) {
  .c-bus__gray {
    padding: 88px 0 120px;
  }
}

@media only screen and (max-width: 767px) {
  .c-bus__gray {
    padding: 75px 0 95px;
  }
}

@media only screen and (max-width: 516px) {
  .c-bus__gray {
    padding: 60px 0 70px;
  }
}

.c-bus__list {
  grid-gap: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 81px 0 10px;
}

@media only screen and (max-width: 1024px) {
  .c-bus__list {
    margin: 60px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .c-bus__list {
    margin: 40px 0 10px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 2rem;
  }
}

@media only screen and (max-width: 516px) {
  .c-bus__list {
    margin: 30px 0 10px;
  }
}

.c-bus__detail {
  margin-top: 15px;
  letter-spacing: 1px;
}

@media only screen and (max-width: 516px) {
  .c-bus__detail {
    margin-top: 10px;
  }
}

.c-bus__white {
  margin-top: 95px;
  margin-bottom: 236px;
}

@media only screen and (max-width: 1024px) {
  .c-bus__white {
    margin-bottom: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .c-bus__white {
    margin-bottom: 130px;
    margin-top: 75px;
  }
}

@media only screen and (max-width: 516px) {
  .c-bus__white {
    margin-bottom: 100px;
    margin-top: 56px;
  }
}

.c-bus__meta {
  grid-gap: 4.5rem 2.5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 88px 0 10px;
}

@media only screen and (max-width: 1024px) {
  .c-bus__meta {
    grid-template-columns: repeat(3, 1fr);
    margin: 80px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .c-bus__meta {
    grid-gap: 3.5rem 2rem;
    margin: 40px 0 10px;
  }
}

@media only screen and (max-width: 516px) {
  .c-bus__meta {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 2rem;
  }
}

.c-bus__name {
  text-align: center;
  margin-top: 10px;
  letter-spacing: 1px;
}

.c-privacy__main {
  margin: 85px 0 124px;
}

@media only screen and (max-width: 1024px) {
  .c-privacy__main {
    margin: 60px 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .c-privacy__main {
    margin: 30px 0 70px;
  }
}

@media only screen and (max-width: 516px) {
  .c-privacy__main {
    margin: 10px 0 50px;
  }
}

.c-privacy__part {
  max-width: 712.53px;
  margin: 0 auto;
}

.c-privacy__part h3 {
  font-size: 1.7rem;
  color: #1E9387;
}

.c-privacy__part p {
  margin-top: 3px;
  font-size: 1.4rem;
  line-height: 2.6;
  margin-bottom: 43px;
}

@media only screen and (max-width: 767px) {
  .c-privacy__part p {
    margin-bottom: 30px;
  }
}

.c-privacy__link {
  max-width: 247.21px;
  display: block;
  height: 56.46px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  color: white;
  background-color: #1E9387;
  margin-top: 54px;
  letter-spacing: 1px;
  border-radius: 36px;
}

@media only screen and (max-width: 516px) {
  .c-privacy__link {
    margin-top: 40px;
  }
}

.c-news__list {
  max-width: 1065.6px;
  margin: 145px auto 186px;
  padding: 0 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
  .c-news__list {
    margin: 145px auto;
  }
}

@media only screen and (max-width: 767px) {
  .c-news__list {
    flex-wrap: wrap;
    margin: 100px auto 140px;
  }
}

@media only screen and (max-width: 516px) {
  .c-news__list {
    margin: 60px auto 100px;
  }
}

.c-news__left {
  width: 67%;
}

@media only screen and (max-width: 767px) {
  .c-news__left {
    width: 100% !important;
  }
}

.c-news__part {
  margin-bottom: 145px;
}

@media only screen and (max-width: 767px) {
  .c-news__part {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 516px) {
  .c-news__part {
    margin-bottom: 40px;
  }
}

.c-news__part .p-top1__list {
  margin-top: 13px;
  letter-spacing: 0px;
  margin-left: 4px;
}

.c-news__part .p-top1__line {
  border-bottom: 2px dotted #1E9387;
  padding: 19px 0;
  margin-bottom: 0;
}

.c-news__category {
  font-size: 2rem;
  color: #1E9387;
  border-bottom: 1px solid;
  padding-bottom: 5px;
  letter-spacing: 3px;
}

@media only screen and (max-width: 516px) {
  .c-news__category {
    font-size: 1.8rem;
  }
}

.c-news__right {
  width: 24%;
  margin-top: 35px;
}

@media only screen and (max-width: 767px) {
  .c-news__right {
    width: 100%;
    max-width: 250px;
    margin: 30px auto 0;
  }
}

@media only screen and (max-width: 516px) {
  .c-news__right {
    max-width: 200px;
  }
}

.c-news__right a {
  background-color: #1E9387;
  color: white;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 23px 0;
  font-size: 2rem;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

@media only screen and (max-width: 516px) {
  .c-news__right a {
    font-size: 1.8rem;
  }
}

.p-top1__category.green {
  background-color: #1E9387;
  color: white;
  display: inline-block;
}

.c-news__content.detail .p-top1__link {
  color: #1E9387;
}

@media only screen and (max-width: 767px) {
  .c-news__content.detail .p-top1__link {
    margin: 63px auto 40px;
  }
}

@media only screen and (max-width: 516px) {
  .c-news__content.detail .p-top1__link {
    margin: 50px auto 30px;
  }
}

.c-news__content.detail .c-news__left {
  width: 69.5%;
}

.c-news__top {
  display: flex;
  align-items: center;
}

.c-news__top .p-top1__category.green {
  margin-right: 13px;
  margin-left: 0;
}

.c-news__top .p-top1__date {
  letter-spacing: 1px;
}

.c-news__title {
  font-size: 1.8rem;
  border-bottom: 1px solid #1E9387;
  padding-bottom: 20px;
  margin-top: 17px;
  letter-spacing: 4px;
}

@media only screen and (max-width: 516px) {
  .c-news__title {
    font-size: 1.7rem;
    padding-bottom: 15px;
    margin-top: 12px;
    letter-spacing: 2px;
  }
}

.c-news__text {
  line-height: 2;
  margin: 34px 0 100px;
  font-size: 1.5rem;
}

.c-news__text img {
  height: auto;
}

@media only screen and (max-width: 767px) {
  .c-news__text {
    margin: 34px 0 80px;
  }
}

@media only screen and (max-width: 516px) {
  .c-news__text {
    margin: 30px 0 50px;
    font-size: 1.4rem;
  }
}

.c-news__page {
  border-top: 1px solid;
  padding-top: 16px;
  font-size: 1.3rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: -14px;
}

.c-case__list {
  max-width: 1022.07px;
  margin: 107px auto 200px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
  .c-case__list {
    margin: 107px auto;
  }
}

@media only screen and (max-width: 767px) {
  .c-case__list {
    margin: 30px auto;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 516px) {
  .c-case__list {
    margin: 20px auto;
  }
}

.c-case__left {
  width: 73%;
}

@media only screen and (max-width: 767px) {
  .c-case__left {
    width: 100%;
  }
}

.c-case__link {
  position: relative;
  margin-bottom: 35px;
}

.c-case__link span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.8rem;
  letter-spacing: 2px;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 516px) {
  .c-case__link span {
    font-size: 1.6rem;
  }
}

.c-case__right {
  width: 21%;
}

@media only screen and (max-width: 767px) {
  .c-case__right {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.c-case__part {
  margin-bottom: 37px;
}

@media only screen and (max-width: 767px) {
  .c-case__part {
    width: 46%;
  }
}

.c-case__title {
  letter-spacing: 1px;
  background-color: #1E9387;
  color: white;
  padding: 13px 20px;
  font-size: 1.5rem;
}

.c-case__cate {
  padding: 0 9px;
  background-color: #FAFAFA;
}

.c-case__cate a {
  display: block;
  font-size: 1.4rem;
  border-bottom: 2px dotted;
  padding: 15px 26px;
  letter-spacing: 2px;
}

@media only screen and (max-width: 516px) {
  .c-case__cate a {
    padding: 15px 11px;
    letter-spacing: 1px;
  }
}

.c-case__cate a:last-child {
  border-bottom: none;
}

.c-cased__content {
  max-width: 1011.62px;
  margin: 0 auto;
  font-size: 1.5rem;
}

.c-cased__title {
  font-size: 2.4rem;
  color: #1E9387;
  letter-spacing: 3px;
}

@media only screen and (max-width: 767px) {
  .c-cased__title {
    font-size: 2rem;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 767px) {
  .c-cased__title {
    font-size: 1.8rem;
  }
}

.c-cased__list {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 100px 0 200px;
}

@media only screen and (max-width: 1024px) {
  .c-cased__list {
    margin: 70px 0 120px;
  }
}

@media only screen and (max-width: 767px) {
  .c-cased__list {
    flex-wrap: wrap;
    margin: 50px 0 70px;
  }
}

@media only screen and (max-width: 516px) {
  .c-cased__list {
    margin: 30px 0 40px;
  }
}

.c-cased__left {
  width: 72%;
  grid-gap: 4rem 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 767px) {
  .c-cased__left {
    width: 100%;
    grid-gap: 3rem 2rem;
  }
}

@media only screen and (max-width: 516px) {
  .c-cased__left {
    grid-template-columns: repeat(2, 1fr);
  }
}

.c-cased__info {
  text-align: center;
  margin-top: 10px;
  letter-spacing: 2px;
}

.c-cased__right {
  width: 21%;
}

@media only screen and (max-width: 1024px) {
  .c-cased__right {
    width: 23%;
  }
}

@media only screen and (max-width: 767px) and (max-width: 767px) {
  .c-cased__right {
    width: 100%;
    max-width: 250px;
    margin: 60px auto 0;
  }
}

@media only screen and (max-width: 767px) and (max-width: 516px) {
  .c-cased__right {
    margin: 40px auto 0;
    max-width: 200px;
  }
}

.c-cased__right a {
  display: block;
  background-color: #1E9387;
  color: white;
  padding: 15px 20px;
  margin-bottom: 17px;
  letter-spacing: 1px;
}

@media only screen and (max-width: 1024px) {
  .c-cased__right a {
    padding: 15px;
    letter-spacing: 0;
  }
}

@media only screen and (max-width: 767px) {
  .c-cased__right a {
    padding: 15px 20px;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Utility .u-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
